.HomePage {
  h1 {
    font-size: 1.5rem;
    margin-bottom: .5em;
  }

  .home__header {
    background-color: #1c1c1c;
    display: flex;
    flex-direction: column;
  }

  .profile__link {
    align-self: center;
    margin-top: 3.5rem;
    border-radius: 50%;
  }

  .profile__link:hover {
    cursor: normal;
  }

  .profile__img {
    border: 5px solid white;
    width: 156px;
    height: 156px;
    object-fit: cover;
    border-radius: 50%;
  }

  .links {
    right: 0;
    top: 0;
    width: 100%;
    justify-content: space-evenly;
    position: absolute;
    font-size: .85rem;
  }

  .links a {
    text-decoration: none;
    color: white;
    margin: 1rem 0 0 0;
  }

  .chart {
    padding-top: 2rem;
    margin: 1em 0;
    background-color: #1c1c1c;
    width: 100vw;
    height: 300px;
    color: #aaa;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .skills {
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #1c1c1c;
    border-radius: 5px;
    padding: 1em;
    box-sizing: border-box;
    display: flex;
    width: 80%;
    flex-direction: column;
  }

  .skills__grid {
    gap: 1em;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .skills__outer__container {
    color: #aaa;
    display: flex;
    flex-direction: column;
    gap: .5em;
  }

  .skills__inner__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .25em;
  }

  .skill {
    border-radius: 5px;
    font-size: .85rem;
    opacity: .75;
    white-space: nowrap;
    background-color: #ccc;
    color: black;
    padding: .2em 0.4em;
    transition: 300ms;
  }

  .skill:hover {
    opacity: 0.9;
    cursor: default;
  }

  .thesis {
    padding: 1.25em 0;
    background-color: #181818;
    align-items: center;
  }

  .thesis__wrapper {
    align-items: center;
    width: 100%;
  }

  .thesis__text>b,
  .thesis__text>p {
    width: 100%;
  }

  .thesis__text {
    width: 80%;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .thesis__text span,
  .thesis__text li {
    margin-top: 0.5em;
  }

  .projects {
    margin: 2em 0 .5em 0;
    color: black;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #1c1c1c;
    width: 100%;
    border-radius: 5px;
    padding: 2em 0;
    box-sizing: border-box;
  }

  .projects>b {
    color: #aaa;
    margin-bottom: 1em;
  }

  .projects__grid {
    gap: 1em;
    color: white;
    display: grid;
    width: 80%;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  }

  .project {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    aspect-ratio: 16/9;
    width: 100%;
    position: relative;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
  }

  .project__overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    transition: 300ms;
  }

  .project__overlay:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .project__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
  }

  @media screen and (min-width: 320px) {
    h1 {
      font-size: 2rem;
    }
  }

  @media screen and (min-width: 720px) {

    .projects__grid,
    .thesis__text {
      width: 60%;
    }

    .skills,
    .chart {
      width: 60vw;
    }

    .links {
      justify-content: flex-end;
    }

    .links a {
      margin: 1rem 2rem 0 0;
    }
  }
}