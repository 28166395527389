body {
  color: #eee;
  background-color: #181818;
  margin: 0;
  min-height: 100vh;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  width: 100%;
  margin: 2em 0;
}

a {
  color: lightskyblue;
}

main {
  background: #181818;
  position: relative;
  padding-bottom: 5rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.Loading,
div.Error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

form button {
  border: none;
  font-weight: bold;
}

form button:hover {
  cursor: pointer;
  opacity: 0.9;
  transition: 300ms;
}

/* utility */
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.w-full {
  width: 100%;
}