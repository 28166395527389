.CreatePage {
  justify-content: center;
  padding: 0;

  form {
    width: 60%;
    gap: .5em;
  }

  .form__header {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .form__header__inner {
    gap: .5em;
    display: flex;
    width: 60%;
    padding-bottom: 1em;
  }

  .form__header__inner input {
    width: 100%;
  }

  form button[type="submit"] {
    padding: 1em .5em;
    color: black;
    border-radius: 5px;
    background-color: greenyellow;
  }

  input {
    background-color: #eee;
    border: none;
    border-radius: 5px;
    padding: 1em;
  }
}