.ChatComponent {
  height: 40vh;
  background: #1c1c1c;
  box-sizing: border-box;
  padding: 2rem 0;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .chat__title {
    /* position: absolute; */
    top: -2rem;
    left: 0;
    background: #222;
    padding: 2em 0;
    width: 100%;
    text-align: center;
  }

  .chat__btn {
    transition: background-color 500ms ease-in-out;
    margin-left: 1em;
    width: 6em;
    color: white;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    transition: 300ms;
  }

  .chat__convo {
    justify-content: flex-end;
    width: 80%;
    height: 100%;
    font-size: .85em;
    mask-image: linear-gradient(to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 20%,
        rgba(0, 0, 0, 0.8) 40%,
        rgba(0, 0, 0, 1) 100%);
    -webkit-mask-image: linear-gradient(to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2) 20%,
        rgba(0, 0, 0, 0.8) 40%,
        rgba(0, 0, 0, 1) 100%);
  }

  .chat__convo b {
    color: white;
  }

  .chat__convo>p {
    margin: 0 0 1em 0;
  }

  .chat__convo>p:nth-child(odd) {
    background-color: #444;
    padding: 1em;
    border-radius: 5px;
  }

  .chat__convo__content {
    color: #ddd;
  }

  .chat__btn:hover {
    transform: scale(1.05);
  }

  .chat__btn:disabled {
    background: #888;
  }

  form {
    width: 80%;
  }

  .chat__input {
    color: white;
    flex: 1;
    background: #666;
    padding: 1.25em;
    border-radius: 5px;
    border: none;
  }

  .chat__input:focus {
    outline: none;
  }

  @media screen and (min-width: 720px) {

    .chat__convo,
    form {
      width: 60%;
    }
  }
}